import { useState, useEffect, useContext, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { centeredPopperElement } from 'app/utils/helper-functions';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { useSlate } from 'slate-react';
import { v4 as uuidv4 } from 'uuid';
import { insertHighLight } from 'app/slate/extensions/highlight';
import { Portal } from '../Portal';
import { UserContext } from 'app/state/contexts';

export const AddComment = ({ paragraph, element }) => {
  const editor = useSlate();
  const { user } = useContext(UserContext);

  const { superId: sectionSuperId } = useEditorSelector((editor) => editor.section);
  const selectionId = useEditorSelector((editor) => editor.selectionId);
  const [show, setShow] = useState(true);
  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useMemo(() => centeredPopperElement(paragraph), [paragraph]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
  });
  const { setDraftComment } = useContext(SidebarContext);
  const handleClick = (e) => {
    e.preventDefault();
    const citation = window.getSelection().toString() || null;
    const commentId = uuidv4();
    const newComment = {
      id: commentId,
      sectionSuperId: sectionSuperId,
      onType: element.type,
      onId: element.uuid,
      message: '',
      userId: user.id,
      citation,
      closedAt: null,
      createdAt: new Date(),
      updatedAt: null,
      publishedAt: null,
      responses: [],
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
    };
    setDraftComment(newComment);
    insertHighLight(editor, commentId);
  };

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);

  if (!show) {
    return null;
  }

  return (
    <Portal>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div className="comment--add-coment">
          <i onMouseDown={handleClick} className="fa-regular fa-message-plus" />
        </div>
      </div>
    </Portal>
  );
};
