import moment from 'moment';
import Tippy from '@tippyjs/react';
import { CommentControls } from './CommentControl';

export const CommentHeader = ({ isActiveComment, comment, setMenuOpen, menuOpen, actionHandler }) => {
  const author = comment.user ? `${comment.user.firstName} ${comment.user.lastName}` : 'John Doe';

  return (
    <div className="comment--header">
      <div className="inner">
        <div className={`${isActiveComment ? 'active' : ''} comment--header`}>
          <div className="comment--header--icon user--circle">
            <div style={{ lineHeight: '2rem' }}>{initials(author)}</div>
          </div>
        </div>

        <div className="comment--header--author">
          <div className="comment--header--author--name">{author}</div>

          <div className="comment--header--author--date">{moment(comment.createAt).format('YYYY-MM-DD HH:mm')}</div>
        </div>
      </div>

      <div className="comment--controls--wrapper">
        {!comment.closedAt && comment.publishedAt && (
          <button type="button" className="not-a-button comment-icon" onClick={(e) => actionHandler('close', e)}>
            <i className="fa-regular fa-check" />
          </button>
        )}
        {!comment.publishedAt && (
          <button type="button" className="not-a-button comment-icon" onClick={(e) => actionHandler('edit', e)}>
            <i className="fa-regular fa-pen" />
          </button>
        )}
        {comment.closedAt && (
          <div className="comment--archived">
            <div>
              <i className="fa-regular fa-box-archive"></i>
            </div>
            Arkiverad
          </div>
        )}
        <Tippy
          content={<CommentControls comment={comment} actionHandler={actionHandler} setMenuOpen={setMenuOpen} />}
          onClickOutside={() => setMenuOpen(false)}
          placement="bottom-end"
          interactive={true}
          appendTo="parent"
          visible={menuOpen}
        >
          <button
            type="button"
            className="not-a-button comment-icon"
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(!menuOpen);
            }}
          >
            <i className="fa-regular fa-ellipsis-v" />
          </button>
        </Tippy>
      </div>
    </div>
  );
};

const initials = (name) =>
  name
    .split(' ')
    .map((w) => w.substr(0, 1))
    .map((c) => c.toUpperCase())
    .join('');
