import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useComments } from 'app/api/useComments';
import { useMemo, useState, useContext, useEffect } from 'react';
import { serialize } from 'app/slate/compareUtils';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { toggleHighlight } from 'app/slate/extensions/highlight';
import { useSlate } from 'slate-react';
import { Comment } from './Comment';

export const Comments = () => {
  const { superId: sectionSuperId, content } = useEditorSelector((editor) => editor.section);
  const [activeComment, setActiveComment] = useState(null);
  const [activeResponse, setActiveResponse] = useState(null);
  const [includeClosedComments, setIncludeClosedComments] = useState(false);
  const { comments, isCommentsLoading } = useComments(sectionSuperId);
  const { draftComment } = useContext(SidebarContext);

  const editor = useSlate();
  const toggleActiveComment = (newActiveComment) => {
    if (!newActiveComment) {
      setActiveComment(null);
      toggleHighlight(editor, '');
    } else {
      setActiveComment(newActiveComment);
      toggleHighlight(editor, newActiveComment.id);
    }
  };
  const reorderedComments = useMemo(() => {
    let flatDocument = {};
    if (content && Object.keys(content).length !== 0) {
      flatDocument = serialize({ content });
    }
    if (isCommentsLoading) {
      return [];
    }

    let orderedComments = comments.map((comment) => (Object.keys(flatDocument).includes(comment.onId) ? comment : null)).filter(Boolean);

    if (!includeClosedComments) {
      orderedComments = orderedComments.filter((comment) => !comment.closedAt);
    }

    const commentsWithResponses = orderedComments.map((comment) => {
      const responses = comments.filter((response) => response.onType === 'comment' && response.onId === comment.id) || [];
      return { ...comment, responses };
    });
    return commentsWithResponses;
  }, [content, isCommentsLoading, comments, includeClosedComments]);

  useEffect(() => {
    if (draftComment) {
      setActiveComment(draftComment);
      toggleHighlight(editor, draftComment.id);
    }
    return () => {
      setActiveComment(null);
      setActiveResponse(null);
      toggleHighlight(editor, '');
    };
  }, [editor, draftComment]);

  const noComments = reorderedComments.length === 0 && !isCommentsLoading && !draftComment;
  return (
    <div className="preview--container h-100">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('doc-editor-link.sidebar.comments-tooltip')}</div>
      </div>
      <div className={`comment--list ${noComments ? 'no-comments' : ''}`}>
        {!noComments && (
          <div className="px-3 comment--list--filter pb-3">
            <p>{i18n('doc-editor-link.sidebar.comment.show-closed')}</p>
            <div className="form-switch">
              <input
                className="form-check-input"
                value={includeClosedComments}
                onChange={(e) => setIncludeClosedComments(!includeClosedComments)}
                type="checkbox"
                role="switch"
              />
            </div>
          </div>
        )}
        {noComments && (
          <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <i className="fa-regular fa-message-xmark" />
            {i18n('doc-editor-link.sidebar.no-comments')}
          </div>
        )}
        {draftComment && <Comment comment={draftComment} key={draftComment.id} activeComment={activeComment} setActiveComment={toggleActiveComment} />}
        {isCommentsLoading ? (
          <div>{i18n('common.loading-message')}</div>
        ) : (
          reorderedComments.map((comment) => (
            <Comment
              key={comment.id}
              comment={comment}
              responses={comment.responses}
              activeComment={activeComment}
              setActiveComment={toggleActiveComment}
              activeResponse={activeResponse}
              setActiveResponse={setActiveResponse}
            />
          ))
        )}
      </div>
    </div>
  );
};
