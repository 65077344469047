import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useComments } from 'app/api/useComments';
import { useState, useContext, useRef, useLayoutEffect, useEffect } from 'react';
import { EditorContext } from 'app/state/contexts/EditorContext';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { UserContext } from '../../../../state/contexts/UserContext';
import { toggleHighlight } from 'app/slate/extensions/highlight';
import { useSlate } from 'slate-react';
import { MentionInput } from '../../../MentionInput';
import { v4 as uuidv4 } from 'uuid';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { setIsDirty } from 'app/state/redux/documentSlice';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { CommentHeader } from './CommentHeader';
import { Response } from './Response';
import { i18n } from 'app/utils/i18n';

export const Comment = ({ comment, responses, activeComment, setActiveComment, activeResponse, setActiveResponse }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  //const [message, setMessage] = useState(comment.message ?? '');
  const [currentComment, setCurrentComment] = useState(comment);
  const [isUpdating, setIsUpdating] = useState(false);
  const [responseComment, setResponseComment] = useState(null);
  const [showResponses, setShowResponses] = useState(false);
  const { superId: sectionSuperId } = useEditorSelector((editor) => editor.section);
  const { setDraftComment, draftComment } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const { shadowHost } = useContext(EditorContext);
  const { deleteComment, updateCommentStatus, publishComment, createCommentAsync, updateCommentMessageAsync } = useComments(sectionSuperId);
  const editorDispatch = useEditorDispatch();

  const editor = useSlate();
  const inputRef = useRef(null);
  const responseRef = useRef(null);
  const isActiveComment = activeComment?.id === comment.id;
  const isResponse = comment.onType === 'comment';

  useEffect(() => {
    setResponseComment((prev) => ({
      ...prev,
      id: prev?.id || uuidv4(),
      userId: user.id,
      onId: comment.id,
      onType: 'comment',
      citation: '',
      message: prev?.message || '',
      sectionSuperId,
      createdAt: prev?.createdAt || new Date(),
      updatedAt: new Date(),
      publishedAt: null,
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
    }));
  }, [comment.id, user.id, user.firstName, user.lastName, user.email, sectionSuperId]);

  const handleDelete = () => {
    const element = shadowHost.shadowRoot.querySelector(`#anchor-${comment.onId}`);
    element?.parentElement?.classList.remove('layout-leaf-comment');
    deleteComment(comment.id);
    toggleHighlight(editor, '');
  };

  useLayoutEffect(() => {
    if ((!comment.publishedAt && inputRef.current) || isUpdating) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
    }
  }, [comment.publishedAt, isUpdating]);

  const saveResponseMessage = async () => {
    editorDispatch(setIsDirty(true));
    if (responseComment.publishedAt) {
      await updateCommentMessageAsync({ commentId: responseComment.id, message: responseComment.message });
    } else {
      const newResponseComment = { ...responseComment, message: responseComment.message, publishedAt: null };
      await createCommentAsync({ sectionSuperId, comment: newResponseComment });
    }
    editorDispatch(setIsDirty(false));
  };

  const handleResponseChange = (e) => {
    setResponseComment({ ...responseComment, message: e });
    saveResponse();
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    const newResponseComment = { ...responseComment, message: responseComment.message, publishedAt: new Date() };
    await createCommentAsync({ sectionSuperId, comment: newResponseComment });
    setIsUpdating(false);
    setActiveComment(null);
    setDraftComment(null);
  };
  const [saveResponse] = useDelayedSave(saveResponseMessage, 1500);

  const saveComment = async (message) => {
    console.log('comment message:', message, comment.updatedAt);
    editorDispatch(setIsDirty(true));
    if (comment.updatedAt) {
      await updateCommentMessageAsync({ commentId: comment.id, message: currentComment.message });
    } else {
      const newComment = { ...comment, message: currentComment.message, publishedAt: null };
      await createCommentAsync({ sectionSuperId, comment: newComment });
    }
    editorDispatch(setIsDirty(false));
  };
  const [save, cancel] = useDelayedSave(async (message) => {
    await saveComment(message);
  }, 1500);

  const handleChange = (e) => {
    cancel();
    setCurrentComment((prev) => ({ ...prev, message: e }));
    save(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveComment(currentComment.message);
    publishComment({ commentId: currentComment.id, publish: true });
    setIsUpdating(false);
    setDraftComment(null);
    setActiveComment(null);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!comment.publishedAt) {
      setDraftComment(null);
    } else {
      setIsUpdating(false);
    }
    setActiveComment(null);
  };

  const actionHandler = (action, event) => {
    event.stopPropagation();
    switch (action) {
      case 'edit':
        setIsUpdating(true);
        break;
      case 'delete':
        handleDelete();
        break;
      case 'open':
        updateCommentStatus({ commentId: comment.id, status: false });
        break;
      case 'close':
        updateCommentStatus({ commentId: comment.id, status: true });
        break;
      default:
        return;
    }
    setMenuOpen(false);
  };

  return (
    <div
      className={`${isActiveComment ? 'active' : ''} ${!comment.publishedAt ? 'draft' : ''} ${comment.closedAt ? 'archived' : ''} comment`}
      onClick={() => {
        setActiveComment(comment);
      }}
    >
      <CommentHeader
        isActiveComment={isActiveComment}
        comment={comment}
        isUpdating={isUpdating}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        actionHandler={actionHandler}
      />
      {isUpdating || !comment.updatedAt ? (
        <>
          <MentionInput value={currentComment.message} onChange={handleChange} ref={inputRef} placeHolder={'Skriv kommentar'} />
          <div className="comment--buttons">
            <button onClick={handleCancel} className="btn btn-ghost btn-md">
              {i18n('common.button.cancel')}
            </button>
            <button
              onClick={async () => {
                await saveComment(currentComment.message);
              }}
              className="btn btn-secondary btn-md "
            >
              {i18n('common.save-draft')}
            </button>
            <button onClick={handleSubmit} className="btn btn-primary btn-md">
              {i18n('common.publish')}
            </button>
          </div>
        </>
      ) : (
        <div className="comment--citation">{formatMessage(comment.message)}</div>
      )}
      {comment?.responses?.length > 0 && (
        <button
          className="not-a-button comment--response-button"
          onClick={(e) => {
            e.stopPropagation();
            setShowResponses(!showResponses);
          }}
        >
          <p>
            {comment?.responses.length} {i18n('comments.response')}
          </p>
        </button>
      )}

      {showResponses && (
        <div className="comment--response--wrapper">
          {responses.map((response) => {
            return (
              <Response
                key={response.id}
                response={response}
                isActiveResponse={activeResponse}
                setActiveResponse={setActiveResponse}
                sectionSuperId={sectionSuperId}
              />
            );
          })}
        </div>
      )}
      {isActiveComment && !draftComment && !isUpdating && !isResponse && (
        <div className="comment--response--wrapper">
          <MentionInput value={responseComment?.message ?? ''} onChange={handleResponseChange} ref={responseRef} placeHolder={'Svara på kommentar'} />
          <div className="comment--buttons">
            <button onClick={handleCancel} className="btn btn-ghost">
              {i18n('common.button.cancel')}
            </button>

            <button onClick={handleResponseSubmit} className="btn btn-primary ">
              {i18n('comments.response.button.save')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const formatMessage = (text) => {
  const parts = text.split(/(@\w+)/g);
  return parts.map((part, index) =>
    part.startsWith('@') ? (
      <span key={index} className="comment--mention">
        {part}
      </span>
    ) : (
      part
    )
  );
};
