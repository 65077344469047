import { useQuery, useMutation } from 'react-query';
import { request } from './request';

export const useComments = (sectionSuperId) => {
  const {
    data: comments,
    isLoading: isCommentsLoading,
    refetch,
  } = useQuery(
    ['comments', sectionSuperId],
    async () => {
      const res = await request.get(`/gaby/comments/${sectionSuperId}`);
      return res.data;
    },
    {
      enabled: !!sectionSuperId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const createComment = useMutation(
    async ({ comment }) => {
      const res = await request.post(`/gaby/comments/${sectionSuperId}`, { data: comment });
      return res.data;
    },
    {
      enabled: !!sectionSuperId,
    }
  );

  const deleteComment = useMutation(
    async (commentId) => {
      await request.del(`/gaby/comments/${commentId}`);
    },
    {
      enabled: !!sectionSuperId && !!comments,
      onSuccess: () => {
        //queryClient.invalidateQueries(['comments', sectionSuperId]);
      },
    }
  );

  const updateCommentMessage = useMutation(async ({ commentId, message }) => {
    await request.put(`/gaby/comments/${commentId}`, { message });
  });

  const updateCommentStatus = useMutation(
    async ({ commentId, status }) => {
      await request.put(`/gaby/comments/${commentId}/status`, { status });
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['comments', sectionSuperId]);
      },
    }
  );

  const publishComment = useMutation(
    async ({ commentId, publish }) => {
      await request.put(`/gaby/comments/${commentId}/publish`, { publish });
    },

    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['comments', sectionSuperId]);
      },
    }
  );

  return {
    //Queries
    comments,
    isCommentsLoading,

    //Refetch
    refetchComments: refetch,
    //Mutations
    createComment: createComment.mutate,
    createCommentAsync: createComment.mutateAsync,

    deleteComment: deleteComment.mutate,
    updateCommentMessage: updateCommentMessage.mutate,
    updateCommentMessageAsync: updateCommentMessage.mutateAsync,

    updateCommentStatus: updateCommentStatus.mutate,
    publishComment: publishComment.mutate,
  };
};
