import { i18n } from 'app/utils/i18n';
import { useContext, useMemo, useState } from 'react';
import { useEditorSelector, EditorContext } from 'app/state/contexts/EditorContext';
import { UserContext } from 'app/state/contexts';
import { useSaveStyle, useStyles } from 'app/api/style-queries';
import { setDocument as setReduxDocument } from 'app/state/redux/documentSlice';

import borderRadiusTopLeft from 'app/icons/border-radius-top-left.svg';
import borderRadiusTopRight from 'app/icons/border-radius-top-right.svg';
import borderRadiusBottomLeft from 'app/icons/border-radius-bottom-left.svg';
import borderRadiusBottomRight from 'app/icons/border-radius-bottom-right.svg';

import seperateSettings from 'app/icons/separate-settings.svg';
import spacingHorizontal from 'app/icons/spacing-horizontal.svg';
import spacingVertical from 'app/icons/spacing-vertical.svg';
import spacingBottom from 'app/icons/spacing-bottom.svg';
import spacingTop from 'app/icons/spacing-top.svg';
import spacingRight from 'app/icons/spacing-right.svg';
import spacingLeft from 'app/icons/spacing-left.svg';

import borderThickness from 'app/icons/border-thickness.svg';
import Dropdown from 'app/components/Dropdown';
import Tippy from '@tippyjs/react';

import { isInsideStyledParagraph } from 'app/slate/extensions/styled-paragraph';
import { useSlate } from 'slate-react';

import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { useDelayedSave } from '../../../../utils/hooks/delayed-save';

export const StyledParagraph = () => {
  const { pDocument } = useEditorSelector((editor) => editor);
  const activeSection = useEditorSelector((editor) => editor.activeSection);
  const editor = useSlate();
  const graphicalStyleId = pDocument.content.design.find((d) => d.type === 'graphical-variables')?.styleId;
  const pageStyleId = pDocument.content.design.find((d) => d.pageId === activeSection.uuid)?.styleId;
  const { selectedOrganization } = useContext(UserContext);
  const { shadowHost } = useContext(EditorContext);

  const editorDispatch = useEditorDispatch();
  const { data: stylesData = [] } = useStyles({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    styleIds: [pageStyleId, graphicalStyleId],
  });
  const [pageStyle, graphicalStyle] = stylesData;

  const savedStyle = pageStyle?.content;

  const { mutate: saveStyle } = useSaveStyle({
    organization: selectedOrganization,
    documentSuperId: pDocument.superId,
    pageId: activeSection.uuid,
    templateSuperId: activeSection.templateSuperId,
  });
  const [localStyle, setLocalStyle] = useState(null);
  const styledParagraphNode = isInsideStyledParagraph(editor);
  const style = localStyle ?? savedStyle;
  const nodeStyle = style?.[`xrp-super-id-${styledParagraphNode?.id}`];

  const colors = useMemo(
    () =>
      Object.keys(COLOR_MAP).map((key) => {
        return {
          name: key,
          title: i18n(COLOR_MAP[key].label),
          value: graphicalStyle?.content[key],
          icon: <div style={{ height: '10px', width: '10px', backgroundColor: graphicalStyle?.content[key] }} />,
        };
      }),
    [graphicalStyle?.content]
  );
  const handleChange = (newProperties) => {
    const newStyle = { ...style };
    const classKey = `xrp-super-id-${styledParagraphNode.id}`;
    if (!newStyle[classKey]) {
      newStyle[classKey] = {};
    }

    Object.entries(newProperties).forEach(([propertyKey, propertyValue]) => {
      const isStringInput = propertyKey === 'background-color' || propertyKey === 'border-color' || propertyKey === 'border-style';
      if (
        propertyValue === undefined ||
        propertyValue === null ||
        propertyValue === '' ||
        propertyValue === 'mixe' ||
        (!isStringInput && isNaN(parseInt(propertyValue)))
      ) {
        delete newStyle[classKey][propertyKey];
      } else {
        if (isStringInput) {
          newStyle[classKey][propertyKey] = propertyValue;
        } else {
          newStyle[classKey][propertyKey] = { value: parseFloat(propertyValue), unit: 'px' };
        }
      }
    });

    const paragraphElement = shadowHost.shadowRoot.querySelector(`.xrp-super-id-${styledParagraphNode.id}`);
    paragraphElement.removeAttribute('style');
    setLocalStyle(newStyle);
    const cssProperties = {};

    Object.entries(newStyle[classKey]).forEach(([key, value]) => {
      if (typeof value === 'object' && value.value !== undefined && value.unit !== undefined) {
        cssProperties[key] = `${value.value === '' ? 0 : value.value}${value.unit}`;
      } else {
        cssProperties[key] = value;
      }
    });
    Object.entries(cssProperties).forEach(([key, value]) => {
      paragraphElement.style.setProperty(key, value);
    });
    save();
  };

  const saveSettings = () => {
    const classStyle = { [`xrp-super-id-${styledParagraphNode.id}`]: nodeStyle };
    saveStyle(classStyle, {
      onSuccess: (doc) => {
        editorDispatch(setReduxDocument(doc.data));
      },
    });
  };
  const [save] = useDelayedSave(saveSettings, 1500);

  const formatPlaceholder = (key) => {
    let values = [];
    if (!nodeStyle) return;

    const filterStyles = (prefix, suffixes) =>
      Object.entries(nodeStyle)
        .filter(([k]) => k.startsWith(prefix) && suffixes.some((suffix) => k.endsWith(suffix)))
        .map(([, v]) => `${v.value}`);

    switch (key) {
      case 'border-radius':
        values = filterStyles('border', ['radius']);
        break;
      case 'margin-horizontal':
        values = filterStyles('margin', ['left', 'right']);
        break;
      case 'margin-vertical':
        values = filterStyles('margin', ['top', 'bottom']);
        break;
      case 'padding-horizontal':
        values = filterStyles('padding', ['left', 'right']);
        break;
      case 'padding-vertical':
        values = filterStyles('padding', ['top', 'bottom']);
        break;
      case 'border':
        values = filterStyles('border', ['width']);
        break;
      default:
        values = [];
        break;
    }

    if (values.length === 0) return '';
    const uniqueValues = new Set(values);
    return uniqueValues.size === 1 ? `${values[0]}` : 'mixed';
  };

  if (!styledParagraphNode)
    return (
      <div className="preview--container" style={{ height: '100%' }}>
        <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
          <div className="fw-bold">{i18n('styledparagraph.sidebar.title')}</div>
        </div>
        <div className="styled-paragraph-wrapper px-3 no-paragraph">
          <p>{i18n('styledparagraph.sidebar.no-paragraph')}</p>
        </div>
      </div>
    );
  return (
    <div className="preview--container h-100">
      <div className="px-3 pt-4 pb-3 w-100 bg-white sticky-top">
        <div className="fw-bold">{i18n('styledparagraph.sidebar.title')}</div>
      </div>
      <div className="styled-paragraph-wrapper px-3" style={{ height: '100%' }}>
        <div className="styled-paragraph-setting-wrapper">
          <p>{i18n('styledparagraph.sidebar.border-radius')}</p>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <img src={borderRadiusTopLeft} alt="Border radius icon" />
            <input
              placeholder={formatPlaceholder('border-radius')}
              type="text"
              pattern="[0-9]*"
              value={formatPlaceholder('border-radius') ?? ''}
              onChange={(e) => {
                handleChange({
                  'border-top-left-radius': e.target.value,
                  'border-top-right-radius': e.target.value,
                  'border-bottom-left-radius': e.target.value,
                  'border-bottom-right-radius': e.target.value,
                });
              }}
            />
            <Tippy
              content={<SettingsPopOver type="border-radius" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>

        <div className="styled-paragraph-setting-wrapper">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{i18n('styledparagraph.sidebar.margin-title')}</p>
            <p>{i18n('styledparagraph.sidebar.margin-description')}</p>
          </div>
          <div style={{ gap: '8px' }} className="styled-paragraph-setting-wrapper">
            <div className="styled-paragraph-setting-inner">
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingHorizontal} alt="Spacing Horizontal Icon" />
                <input
                  placeholder={formatPlaceholder('margin-horizontal')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('margin-horizontal') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({ 'margin-right': value, 'margin-left': value });
                  }}
                />
              </div>
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingVertical} alt="Spacing Vertical Icon" />
                <input
                  placeholder={formatPlaceholder('margin-vertical')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('margin-vertical') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'margin-top': value,
                      'margin-bottom': value,
                    });
                  }}
                />
              </div>
            </div>
            <Tippy
              content={<SettingsPopOver type="margin" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
        <div className="styled-paragraph-setting-wrapper">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{i18n('styledparagraph.sidebar.padding-title')}</p>
            <p>{i18n('styledparagraph.sidebar.padding-description')}</p>
          </div>
          <div style={{ gap: '8px' }} className="styled-paragraph-setting-wrapper">
            <div className="styled-paragraph-setting-inner">
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingHorizontal} alt="Spacing Horizontal Icon" />
                <input
                  placeholder={formatPlaceholder('padding-horizontal')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('padding-horizontal') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'padding-left': value,
                      'padding-right': value,
                    });
                  }}
                />
              </div>
              <div className="styled-paragraph-input-wrapper">
                <img src={spacingVertical} alt="Spacing Vertical Icon" />
                <input
                  placeholder={formatPlaceholder('padding-vertical')}
                  type="text"
                  pattern="[0-9]*"
                  value={formatPlaceholder('padding-vertical') ?? ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      'padding-top': value,
                      'padding-bottom': value,
                    });
                  }}
                />
              </div>
            </div>
            <Tippy
              content={<SettingsPopOver type="padding" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
        <div className="divider horizontal bg-blue-500" />
        <div>
          <p className="mb-r-1">{i18n('styledparagraph.sidebar.background-color')}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            <Dropdown items={colors} sm value={nodeStyle?.['background-color']} onChange={(e) => handleChange({ 'background-color': e })} />
            <div className="styled-paragraph-color-icon" style={{ background: nodeStyle?.['background-color'] }} />
          </div>
        </div>
        <div className="divider horizontal bg-blue-500" />
        <div>
          <p className="mb-r-1">{i18n('styledparagraph.sidebar.border')}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            <Dropdown items={colors} sm value={nodeStyle?.['border-color']} onChange={(e) => handleChange({ 'border-color': e })} />
            <div className="styled-paragraph-color-icon" style={{ background: nodeStyle?.['border-color'] }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', placeSelf: 'end', marginTop: '16px' }}>
            <img src={borderThickness} alt="Border Thickness Icon" />
            <input
              placeholder={formatPlaceholder('border')}
              type="text"
              pattern="[0-9]*"
              style={{ marginLeft: '16px', marginRight: '8px' }}
              value={formatPlaceholder('border')}
              onChange={(e) => {
                const value = e.target.value;
                handleChange({
                  'border-top-width': value,
                  'border-right-width': value,
                  'border-bottom-width': value,
                  'border-left-width': value,
                  'border-style': value ? 'solid' : 'none',
                });
              }}
            />
            <Tippy
              content={<SettingsPopOver type="border" values={nodeStyle} onChange={handleChange} />}
              placement="left"
              theme="light"
              interactive={true}
              trigger="click"
              appendTo="parent"
            >
              <img src={seperateSettings} alt="Seperate Settings Icon" className="settings-icon" />
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingsPopOver = ({ type, onChange, values }) => {
  const settingsType = type === 'border-radius' ? 'radius' : 'spacing';

  const formatKey = (type, key) => {
    let cssProperty;
    switch (type) {
      case 'margin':
      case 'padding':
        cssProperty = `${type}-${key}`;
        break;
      case 'border':
        cssProperty = `border-${key}-width`;
        break;
      case 'border-radius':
        cssProperty = `border-${key}-radius`;
        break;
      default:
        break;
    }

    return values?.[cssProperty]?.value ?? '';
  };

  const handleSettingsChange = (value, key) => {
    let cssProperty;
    switch (type) {
      case 'margin':
      case 'padding':
        cssProperty = `${type}-${key}`;
        break;
      case 'border':
        cssProperty = `border-${key}-width`;
        break;
      case 'border-radius':
        cssProperty = `border-${key}-radius`;
        break;
      default:
        break;
    }
    onChange({ [cssProperty]: value });
  };

  return (
    <div className="seperate-setting-wrapper">
      <div className="seperate-setting-inner">
        <div>
          <img src={settingsType === 'spacing' ? spacingTop : borderRadiusTopLeft} alt="Spacing Top Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'top' : 'top-left')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'top' : 'top-left')}
          />
        </div>
        <div>
          <img src={settingsType === 'spacing' ? spacingRight : borderRadiusTopRight} alt="Spacing Right Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'right' : 'top-right')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'right' : 'top-right')}
          />
        </div>
      </div>
      <div className="seperate-setting-inner">
        <div>
          <img src={settingsType === 'spacing' ? spacingBottom : borderRadiusBottomLeft} alt="Spacing Top Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'bottom' : 'bottom-left')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'bottom' : 'bottom-left')}
          />
        </div>
        <div>
          <img src={settingsType === 'spacing' ? spacingLeft : borderRadiusBottomRight} alt="Spacing Right Icon" />
          <input
            placeholder="0"
            type="text"
            pattern="[0-9]*"
            value={formatKey(type, settingsType === 'spacing' ? 'left' : 'bottom-right')}
            onChange={(e) => handleSettingsChange(e.target.value, settingsType === 'spacing' ? 'left' : 'bottom-right')}
          />
        </div>
      </div>
    </div>
  );
};

const COLOR_MAP = {
  xrpPrimaryColor: {
    label: 'graphical-variable.xrpPrimaryColor',
  },
  xrpSecondaryColor: {
    label: 'graphical-variable.xrpSecondaryColor',
  },
  xrpTertiaryColor: {
    label: 'graphical-variable.xrpTertiaryColor',
  },
  xrpQuaternaryColor: {
    label: 'graphical-variable.xrpQuaternaryColor',
  },
  xrpHeadingColor: {
    label: 'graphical-variable.xrpHeadingColor',
  },
};
