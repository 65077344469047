import { useState, createContext } from 'react';
export const SidebarContext = createContext();

export const SidebarContextProvider = ({ children }) => {
  const [styledParagraph, setStyledParagraph] = useState(null);
  const [draftComment, setDraftComment] = useState(null);

  return (
    <SidebarContext.Provider
      value={{
        draftComment,
        setDraftComment,
        styledParagraph,
        setStyledParagraph,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
