import { CommentHeader } from './CommentHeader';
import { MentionInput } from '../../../MentionInput';
import { useState, useRef, useContext } from 'react';
import { useComments } from 'app/api/useComments';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { useEditorDispatch } from 'app/state/contexts/EditorContext';
import { setIsDirty } from 'app/state/redux/documentSlice';
import { i18n } from 'app/utils/i18n';

const formatMessage = (text) => {
  const parts = text.split(/(@\w+)/g);
  return parts.map((part, index) =>
    part.startsWith('@') ? (
      <span key={index} className="comment--mention">
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const Response = ({ response, activeResponse, setActiveResponse, sectionSuperId }) => {
  const [responseComment, setResponseComment] = useState(response);
  const responseRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { updateCommentStatus, updateCommentMessageAsync, createCommentAsync, publishComment, deleteComment } = useComments();
  const { setDraftComment } = useContext(SidebarContext);
  const editorDispatch = useEditorDispatch();

  const saveResponseMessage = async () => {
    editorDispatch(setIsDirty(true));
    if (response.publishedAt) {
      await updateCommentMessageAsync({ commentId: response.id, message: response.message });
    } else {
      const newResponseComment = { ...response, message: response.message, publishedAt: null };
      await createCommentAsync({ sectionSuperId, comment: newResponseComment });
    }
    editorDispatch(setIsDirty(false));
  };
  const [saveResponse] = useDelayedSave(saveResponseMessage, 1500);
  const actionHandler = (action, event) => {
    event.stopPropagation();
    switch (action) {
      case 'edit':
        setIsUpdating(true);
        break;
      case 'delete':
        deleteComment(responseComment.id);
        break;
      case 'open':
        updateCommentStatus({ commentId: response.id, status: false });
        break;
      case 'close':
        updateCommentStatus({ commentId: response.id, status: true });
        break;
      default:
        return;
    }
    setIsMenuOpen(false);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!response.publishedAt) {
      setDraftComment(null);
    } else {
      setIsUpdating(false);
    }
    setResponseComment(null);
  };

  const handleResponseSubmit = async (e) => {
    e.preventDefault();
    await saveResponseMessage();
    publishComment({ commentId: response.id, publish: true });
    setIsUpdating(false);
    setDraftComment(null);
  };
  const handleResponseChange = (e) => {
    setResponseComment({ ...responseComment, message: e });
    saveResponse();
  };
  return (
    <div
      className={`${activeResponse ? 'active' : ''} ${!response.publishedAt ? 'draft' : ''} ${response.closedAt ? 'archived' : ''} comment`}
      onClick={(e) => {
        e.stopPropagation();
        setActiveResponse(response);
      }}
    >
      <CommentHeader
        isActiveComment={activeResponse}
        comment={response}
        isUpdating={isUpdating}
        setMenuOpen={setIsMenuOpen}
        menuOpen={isMenuOpen}
        actionHandler={actionHandler}
      />
      {isUpdating || !response.updatedAt ? (
        <>
          <MentionInput value={responseComment.message ?? ''} onChange={handleResponseChange} ref={responseRef} placeHolder={'Skriv kommentar'} />
          <div className="comment--buttons">
            <button onClick={handleCancel} className="btn btn-ghost">
              {i18n('common.button.cancel')}
            </button>

            <button onClick={handleResponseSubmit} className="btn btn-primary ">
              {i18n('common.publish')}
            </button>
          </div>
        </>
      ) : (
        <div className="response--citation">{formatMessage(responseComment.message)}</div>
      )}
    </div>
  );
};
