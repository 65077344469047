import { Portal } from '../Portal';
import { useState, useMemo, useEffect, useContext } from 'react';
import { usePopper } from 'react-popper';
import { centeredPopperElement } from 'app/utils/helper-functions';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { useSlate } from 'slate-react';
import { SidebarContext } from 'app/state/contexts/SidebarContext';
import { insertStyledParagraph } from 'app/slate/extensions/styled-paragraph';
import { v4 as uuidv4 } from 'uuid';
export const AddStyledParagraph = ({ paragraph, element }) => {
  const selectionId = useEditorSelector((editor) => editor.selectionId);
  const { setStyledParagraph } = useContext(SidebarContext);
  const [show, setShow] = useState(true);
  const editor = useSlate();
  const handleClick = (e) => {
    const styledParagraphId = uuidv4();
    insertStyledParagraph(editor, styledParagraphId);
    e.preventDefault();
    setStyledParagraph({ uuid: styledParagraphId });
  };

  const [popperElement, setPopperElement] = useState(null);
  const referenceElement = useMemo(() => centeredPopperElement(paragraph), [paragraph]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
  });

  useEffect(() => {
    setShow(selectionId && selectionId === element.uuid);
  }, [element.uuid, selectionId]);
  if (!show) {
    return null;
  }
  return (
    <Portal>
      <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div className="styled-paragraph--add-paragraph" style={{ marginBottom: '20px' }}>
          <i onMouseDown={handleClick} className="fa-regular fa-rectangle-list" />
        </div>
      </div>
    </Portal>
  );
};
